/* eslint-disable import/no-cycle */

import moment from 'moment';
import { EConversionReasonDB } from 'data/datas/GenericData';
import { TFile } from './Storage.types';
import { TSADocPacketGet, TSADocPacketGetUI } from './AnimalTraining.types';
import {
  EAnimalPetChargeStatusDB,
  EAnimalPetChargeStatusUI,
  EApplicationStatus,
  EApplicationStatusUi,
  EGender,
  EGenderUi,
  EReproductive,
  EValueEditingStates,
  EVerificationStatus,
  EVerificationStatusUi,
  TEditingBooleanField,
  TEditingBooleanFieldUi,
  TEditingField,
  TEditingFieldUi,
  TExpirationDateType,
  TFail,
  TFailUI,
  TFileDataType,
  TFileDataTypeUI,
  TOptionType,
  TOptionTypeUi,
  TPropertyInfoUI,
  TPropertyManagementInfoUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TStatusInfoWithSourceDB,
  TStatusInfoWithSourceUI,
  TVetRecordTypeUI,
} from './GeneralTypes';
import {
  EVetRecordStatus,
  EVetRecordStatusUi,
  TApplicationVetRecords,
  TApplicationVetRecordsUI,
} from './VetRecord.types';
import {
  EAnimalTagDB,
  EAnimalTagUI,
  ETenantTagDB,
  ETenantTagUI,
} from './Tags.types';
import {
  TApplicationHPPacket,
  TApplicationHPPacketUI,
} from './Applications.types';

export enum EAnimalActionDB {
  CONVERT = 'CONVERT',
}

export enum EAnimalActionUI {
  CONVERT = 'CONVERT',
}

export type TBlockedAction = {
  animalAction: EAnimalActionDB;
  reason: string;
};

export type TBlockedActionUI = {
  animalAction: EAnimalActionUI;
  reason: string;
};

export type TOptionTypeUI = {
  key: string;
  id: string;
  value: string;
};

export type TAnimalDetailObjectProps = {
  value: string;
  predefined: boolean;
};

export type TAnimalWeightProps = {
  value: number;
  unit: string;
};

export type TAnimalPostUI = {
  id: string;
  authorized: boolean;
  complete: boolean;
  name: string;
  profilePicture: string;
  type: string;
  species: string;
  breed?: string;
  weight?: number;
};

export type TAnimalGet = {
  id: string;
  authorized: boolean;
  complete: boolean;
  name: string;
  profilePicture: string;
  type: string;
  species: TAnimalDetailObjectProps;
  breed?: TAnimalDetailObjectProps;
  weight?: TAnimalWeightProps;
};

export type TGenderUI = { gender: EGenderUi; neutered: boolean };
export type TGender = { type: EGender; isReproductive: boolean };

export enum EAnimalTypesUI {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export enum EAnimalTypes {
  PET = 'PET',
  ESA = 'ESA',
  SA = 'SA',
}

export type TAnimalFormDataUI = {
  name: string;
  birthDate: moment.Moment;
  breed: TOptionTypeUi;
  species: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  weight: string;
  profilePicture: TFile | null;
  gender: EGenderUi;
  neutered: boolean;
  houseTrained: boolean;
  animalType: EAnimalTypesUI;
};

export type TAnimalFormData = {
  animalType: EAnimalTypes;
  name: string;
  birthDate: string;
  species: TOptionType;
  breed?: TOptionType | null;
  primaryColor: TOptionType;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  weight: string | null;
  profilePicture?: string | null;
  gender: TGender;
  houseTrained: boolean;
};

export type TCompleteAnimalFormDataUI = {
  name: string;
  birthDate: moment.Moment;
  breed: TOptionTypeUi;
  species: TOptionTypeUi;
  primaryColor: TOptionTypeUi;
  secondaryColor1: TOptionTypeUi;
  secondaryColor2: TOptionTypeUi;
  weight: string;
  profilePicture: TFile | null;
  gender: EGenderUi;
  neutered: boolean;
  houseTrained: boolean;
  animalId: string;
};

export type TCompleteAnimalFormData = {
  animalId: string;
  name: string;
  birthDate: string;
  species: TOptionType;
  breed?: TOptionType | null;
  primaryColor: TOptionType;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  weight: string | null;
  profilePicture?: string | null;
  gender: TGender;
  houseTrained: boolean;
};

export type TAnimalVetRecordsListUi = {
  documentFiles: TFileDataTypeUI[];
  expiration: TExpirationDateType;
  id: string;
  canEdit: boolean;
  type: string;
  status: EVetRecordStatusUi;
};

export type TAnimalVetRecordsList = {
  documentFiles: TFileDataType[];
  expiration: TExpirationDateType;
  id: string;
  locked?: boolean;
  type: EVetRecordStatus;
};

export type TAnimalVetRecordCreateUi = {
  animalId: string;
  expirationDate: string;
  files: TFile[];
  docName: string;
  type: string;
  unexpired: boolean;
};

export type TAnimalVetRecordCreate = {
  animalId: string;
  expiration: TExpirationDateType;
  files: TFile[];
  type: TVetRecordTypeUI;
};

export type TConfirmPaymentUi = {
  animalId: string;
  leasePacketId: string;
};

export type TConfirmPayment = TConfirmPaymentUi;

export type TVetRecordTypeNameUpdate = {
  animalId: string;
  vetRecordId: string;
  type: TVetRecordTypeUI;
};

export type TVetRecordTypeNameUpdateUI = {
  animalId: string;
  vetRecordId: string;
  type: string;
};

export type TVetRecordExpirationDB = {
  vetRecordDateType: string;
  expirationDate: string;
};
export type TVetRecordExpirationUI = {
  vetRecordDateType: string;
  expirationDate: string;
};

export enum EVetRecordTypeUI {
  RABBIES = 'Rabbies',
  PARVO = 'Parvo',
  SPAYED_NEUTERED = 'Spayed/Neutered',
  DOG_LIABILITY_INSURANCE = 'Dog liability insurance',
}

export enum EVetRecordTypeDB {
  RABBIES = 'RABBIES',
  PARVO = 'PARVO',
  SPAYED_NEUTERED = 'SPAYED_NEUTERED',
  DOG_LIABILITY_INSURANCE = 'DOG_LIABILITY_INSURANCE',
}

export enum EVetRecordDateType {
  EXPIRED = 'expired',
  UNEXPIRED = 'unexpired',
}
export type TChangedRecords = {
  expiration: TExpirationDateType;
  id: string;
};
export type TVetRecordExpirationUpdate = {
  animalId: string;
  vetRecordId: string;
  changedRecords: TChangedRecords[];
};

export type TVetRecordExpirationUpdateUI = {
  animalId: string;
  recordId: string;
  vetRecordId: string;
  expirationDate: string;
  vetRecordDateType: EVetRecordDateType;
};

export type TUpdateAnimalTypeUI = {
  animalId: string;
  toType: EAnimalTypesUI;
  reason?: string;
  customReason?: string;
};

export type TUpdateAnimalTypeDB = {
  animalId: string;
  toType: EAnimalTypes;
  reason?: string;
};

export type TAnimalFollowUpUI = {
  animalId: string;
  description: string;
};

export type TAnimalFollowUpDB = {
  animalId: string;
  description: string;
};

export type TApproveAnimalTypeConvUI = {
  animalId: string;
  animalConversionId: string;
};

export type TApproveAnimalTypeConvDB = {
  animalId: string;
  animalConversionId: string;
};

export type TRejectAnimalTypeConvUI = {
  animalId: string;
  animalConversionId: string;
  reason: string;
};

export type TRejectAnimalTypeConvDB = {
  animalId: string;
  animalConversionId: string;
  reason: string;
};

export type TVetRecordDocumentsUI = {
  animalId: string;
  vetRecordId: string;
  docs?: TFileDataTypeUI[];
  removedDocumentsId?: string[];
};

export type TVetRecordDocuments = {
  animalId: string;
  vetRecordId: string;
  newFiles: TFile[];
  removedDocumentsId: string[];
};

export type TAnimalBasicInfo = {
  birthDate: string;
  breed: string;
  gender: EGender;
  id: string;
  isDeleted: boolean;
  isHouseTrained: boolean | null;
  isReproductive: boolean | null;
  name: string;
  photoGallery: string[];
  primaryColor: string | null;
  profilePicture: string | null;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  species: string | null;
  status: EVerificationStatus;
  type: EAnimalTypes;
  weight?: TAnimalWeightProps;
  microchip: string | null;
  isRequiredBecauseOfDisability?: boolean;
  trainedFor?: string;
};

export type TAnimalBasicInfoUi = {
  birthDate: string | null;
  breed: string;
  gender: EGenderUi;
  id: string;
  isDeleted: boolean;
  isHouseTrained: boolean | null;
  reproduction: EReproductive | null;
  name: string;
  photoGallery: string[];
  primaryColor: string | null;
  profilePicture: string | null;
  secondaryColor1: string | null;
  secondaryColor2: string | null;
  species: string | null;
  status: EVerificationStatusUi;
  type: EAnimalTypesUI;
  weight?: TAnimalWeightProps;
  microchip: string | null;
  isRequiredBecauseOfDisability?: boolean;
  trainedFor?: string;
};

export type TTenantDetail = {
  id: string;
  photo: string | null;
  name: string;
  email: string | null;
  phone: string | null;
};

export type TTenantDetailUi = {
  id: string;
  photo: string | null;
  name: string;
  email: string | null;
  phone: string | null;
};

export enum EResidenciesStatus {
  APPLICANT = 'APPLICANT',
  RESIDENT = 'RESIDENT',
  FUTURE = 'FUTURE',
  MOVED_OUT = 'MOVED_OUT',
  REJECTED = 'REJECTED',
  NOTICE = 'NOTICE',
}

export enum EResidenciesStatusUI {
  APPLICANT = 'Applicant',
  RESIDENT = 'Resident',
  FUTURE = 'Future Resident',
  MOVED_OUT = 'Former Resident',
  REJECTED = 'Rejected',
  NOTICE = 'Notice',
}

export enum EAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
}

export enum EAuthorizationStatusUi {
  AUTHORIZED = 'Authorized',
}
export type TPropertyManagerDB = {
  emailAddress: string;
  name: string;
  phone: string | null;
};
export type TPropertyManagerUI = {
  emailAddress: string;
  name: string;
  phone: string | null;
};

export type TAnimalResidencyDB = {
  chargingStatus: null | EAnimalPetChargeStatusDB;
  chargingStatusChangeReason: null | string;
  chargingStatusInfo: TStatusInfoWithSourceDB | null;
  id: string;
  status: EAnimalStatusDB;
  tags: null | EAnimalTagDB[];
  notBeingChargedReason: null | string;
  canTagKnown: boolean;
  discoveredTagReason: string | null;
  policyId: string | null;
  policyDateSigned: string | null;
  policySignedBy: string | null;
  policyContent: string | null;
  policyName: string | null;
  statusChangeInfo: TStatusChangeInfoDB | null;
  missingMoveInDateReason: string | null;
  moveInDate: string | null;
};

export type TAnimalResidencyUI = {
  chargingStatus: null | EAnimalPetChargeStatusUI;
  chargingStatusInfo: TStatusInfoWithSourceUI | null;
  chargingStatusChangeReason: null | string;
  id: string;
  status: EAnimalStatusUI;
  tags: EAnimalTagUI[];
  notBeingChargedReason: null | string;
  canTagKnown: boolean;
  discoveredTagReason: string | null;
  policyId: string | null;
  policyDateSigned: string | null;
  policySignedBy: string | null;
  policyContent: string | null;
  policyName: string | null;
  statusChangeInfo: TStatusChangeInfoUI | null;
  missingMoveInDateReason: string | null;
  moveInDate: string | null;
};

export type TResidencies = {
  id: string;
  animalResidency: TAnimalResidencyDB | null;
  authorizationStatus: EAuthorizationStatus;
  propertyName: string;
  unitNumber: string;
  propertyManagementBusinessName: string;
  propertyManagementBusinessLogo: string;
  leaseStartDate: string;
  notes: TNotesDB[];
  leaseToDate: string;
  policyId: string;
  status: EResidenciesStatus;
  isExpired: boolean;
  tags: ETenantTagDB[];
  propertyManagers: TPropertyManagerDB[];
  statusChangeInfo: TStatusInfoWithSourceDB | null;
};

export type TResidenciesUi = {
  id: string;
  animalResidency: TAnimalResidencyUI | null;
  tags: ETenantTagUI[];
  authorizationStatus: EAuthorizationStatusUi;
  propertyName: string;
  unitNumber: string;
  propertyManagementBusinessName: string;
  propertyManagementBusinessLogo: string;
  leaseStartDate: string;
  notes: TNotesUI[];
  leaseToDate: string;
  policyId: string;
  status: EResidenciesStatusUI;
  isExpired: boolean;
  propertyManagers: TPropertyManagerUI[];
  statusChangeInfo: TStatusInfoWithSourceUI | null;
};
export type TNotesDB = {
  creationTime: string;
  creatorName: string;
  id: string;
  text: string;
};
export type TNotesUI = {
  creationTime: string;
  creatorName: string;
  id: string;
  text: string;
};
export type TAnimalApplicationRelatedPropertyManagers = {
  id: string;
  emailAddress: string | null;
  isLandlord: boolean;
  name: string;
  phoneNumber: string | null;
};

export type TAnimalApplicationRelatedPropertyManagersUi = {
  id: string;
  emailAddress: string | null;
  isLandlord: boolean;
  name: string;
  phoneNumber: string | null;
};

export type TAnimalApplication = {
  id: string;
  propertyName: string;
  unitNumber: string;
  propertyManagementBusinessName: string;
  propertyManagementBusinessLogo: string;
  propertyManagerName: string;
  leaseStartDate: string;
  leaseToDate: string | null;
  applicationStatus: EApplicationStatus;
  policyId: string;
  relatedPropertyManagers: TAnimalApplicationRelatedPropertyManagers[];
  landlord: Omit<TAnimalApplicationRelatedPropertyManagers, 'isLandlord'>;
};

export type TAnimalApplicationUi = {
  id: string;
  propertyName: string;
  unitNumber: string;
  propertyManagementBusinessName: string;
  propertyManagementBusinessLogo: string;
  propertyManagerName: string;
  leaseStartDate: string;
  leaseToDate: string | null;
  applicationStatus: EApplicationStatusUi;
  policyId: string;
  relatedPropertyManagers: TAnimalApplicationRelatedPropertyManagersUi[];
  landlord: Omit<TAnimalApplicationRelatedPropertyManagers, 'isLandlord'>;
};

export type TConversionType = {
  trainedFor?: string;
  trainerDocumentInfoNotProvidedReason: string | null;
  hpDocumentInfoNotProvidedReason: string | null;
  observableDisability: boolean | null;
  createdAt: string;
  fromType: EAnimalTypes;
  hpDocumentPacket: TApplicationHPPacket | null;
  id: string;
  reason: string | null;
  saDocumentPacket: TApplicationSADocPacketGet | null;
  status: EConvertAnimalStatus;
  toType: EAnimalTypes;
  approveActorName: string | null;
};

export type TConversionTypeUI = {
  trainedFor?: string;
  trainerDocumentInfoNotProvidedReason: string | null;
  hpDocumentInfoNotProvidedReason: string | null;
  observableDisability: boolean | null;
  createdAt: string;
  fromType: EAnimalTypesUI;
  hpDocumentPacket: TApplicationHPPacketUI | null;
  id: string;
  reason: string | null | EConversionReasonDB;
  saDocumentPacket: TApplicationSADocPacketGetUI | null;
  status: EConvertAnimalStatusUI;
  toType: EAnimalTypesUI;
  conversionApprover?: string;
  isReasonPredefined: boolean;
};

export type TFollowUpItemUI = {
  authorName: string;
  createdAt: string;
  description: string;
  isCompleteItem: boolean;
  propertyManagementName: string | null;
};

export type TFollowUpItemDB = {
  authorName: string;
  createdAt: string;
  description: string;
  isCompleteItem: boolean;
  propertyManagementName: string | null;
};

export type TAnimalDetail = {
  hasObservableDisability: null | boolean;
  animal: TAnimalBasicInfo;
  tenant: TTenantDetail;
  residencies: TResidencies[] | null;
  applications: TAnimalApplication[] | null;
  vetRecords: TApplicationVetRecords[] | null;
  hpDocPackets: TApplicationHPPacket[] | null;
  saDocuments: TApplicationSADocPacketGet[] | null;
  conversions: TConversionType[] | null;
  blockedActions?: TBlockedAction[];
  followUpItems: TFollowUpItemDB[] | null;
  isFollowUpCompleted: boolean;
  isCompleteNote: boolean;
  statusChangeInfo: TStatusChangeInfoDB | null;
  missingNameReason: string | null;
  missingTypeReason: string | null;
};

export type TAnimalDetailUi = {
  hasObservableDisability: null | boolean;
  animal: TAnimalBasicInfoUi;
  tenant: TTenantDetailUi;
  residencies: TResidenciesUi[] | null;
  applications: TAnimalApplicationUi[] | null;
  vetRecords: TApplicationVetRecordsUI[] | null;
  hpDocPackets: TApplicationHPPacketUI[] | null;
  saDocuments: TApplicationSADocPacketGetUI[] | null;
  conversions: TConversionTypeUI[] | null;
  blockedActions?: TBlockedActionUI[];
  followUpItems: TFollowUpItemUI[] | null;
  isFollowUpCompleted: boolean;
  statusChangeInfo: TStatusChangeInfoUI | null;
  missingNameReason: string | null;
  missingTypeReason: string | null;
};

export enum EConvertAnimalStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export enum EConvertAnimalStatusUI {
  APPROVED = 'Converted',
  REJECTED = 'Not approved',
  PENDING = 'Pending',
}

export type TAnimalWarningVerificationUI = {
  hasNotVerifiedDocument: boolean;
};

export type TAnimalWarningVerificationDB = {
  hasNotVerifiedDocument: boolean;
};

export type TAnimalType = {
  animalId: string;
  newType: string;
};

export type TAnimalTypeUI = {
  animalId: string;
  type: TOptionTypeUi;
};

export type TVetRecordUI = {
  animalId: string;
  vetRecordId: string;
};

export type TVetRecord = {
  animalId: string;
  vetRecordId: string;
};

export type TEditingWeightField = {
  value?: number | null;
  unit?: string | null;
  allowedOperations: EValueEditingStates[];
};

export type TEditingWeightFieldUi = {
  value: string | null;
  unit: string | null;
  canEdit: boolean;
  canRemove: boolean;
};

export type TAnimalDetailsGenderField = {
  value: EGender;
  allowedOperations: EValueEditingStates[];
};

export type TAnimalDetailsGenderFieldUi = {
  value: EGenderUi;
  canEdit: boolean;
  canRemove: boolean;
};

export type TAnimalDetails = {
  id: string;
  name: TEditingField;
  profilePicture: TEditingField;
  species: TEditingField;
  breed: TEditingField;
  primaryColor: TEditingField;
  secondaryColor1: TEditingField;
  secondaryColor2: TEditingField;
  birthDate: TEditingField;
  weight: TEditingWeightField;
  isHouseTrained: TEditingBooleanField;
  gender: TAnimalDetailsGenderField;
  isReproductive: TEditingBooleanField;
  vetRecords: TAnimalVetRecordsList[] | null;
  saDocumentPackets: TSADocPacketGet[] | null;
  type: EAnimalTypes;
};

export type TAnimalDetailsUi = {
  id: string;
  name: TEditingFieldUi;
  profilePicture: TEditingFieldUi;
  species: TEditingFieldUi;
  breed: TEditingFieldUi;
  primaryColor: TEditingFieldUi;
  secondaryColor1: TEditingFieldUi;
  secondaryColor2: TEditingFieldUi;
  birthDate: TEditingFieldUi;
  weight: TEditingWeightFieldUi;
  isHouseTrained: TEditingBooleanFieldUi;
  gender: TAnimalDetailsGenderFieldUi;
  isReproductive: TEditingBooleanFieldUi;
  vetRecordList: TAnimalVetRecordsListUi[];
  packets: TSADocPacketGetUI[];
  type: EAnimalTypesUI;
};

export type TAnimalFieldUpdateUi = {
  id: string;
  value: string;
};

export type TAnimalCreatableFieldUpdateUi = {
  id: string;
  value: TOptionTypeUi;
};

export type TAnimalCreateFieldUpdateUi = {
  id: string;
  value: string;
};

export type TAnimalNameUpdate = {
  animalId: string;
  name: string;
};
export type TAnimalNameUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalHouseTrainedUpdate = {
  animalId: string;
  houseTrained: boolean;
};
export type TAnimalHouseTrainedUpdateUi = {
  id: string;
  value: boolean;
};
export type TAnimalGenderUpdate = {
  animalId: string;
  gender: EGender;
};
export type TAnimalGenderUpdateUi = {
  id: string;
  value: EGenderUi;
};
export type TAnimalProfileUpdate = {
  animalId: string;
  profilePicture: string;
};
export type TAnimalProfileUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalWeightUpdate = {
  animalId: string;
  weight: string | null;
  unit: string;
};
export type TAnimalWeightUpdateUi = TAnimalFieldUpdateUi;
export type TAnimalBirthDate = {
  animalId: string;
  birthDate: string;
};
export type TAnimalBirthDateUi = TAnimalFieldUpdateUi;
export type TAnimalSecondaryColorUpdate = {
  animalId: string;
  colorId: string;
};
export type TAnimalSecondaryColorUpdateUi = TAnimalCreateFieldUpdateUi;
export type TAnimalReproductiveUpdate = {
  animalId: string;
  reproductive: boolean;
};
export type TAnimalReproductiveUpdateUi = {
  id: string;
  value: boolean;
};
export type TAnimalSpeciesUpdate = {
  animalId: string;
  species: TOptionType;
};
export type TAnimalSpeciesUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalPrimaryColorUpdate = {
  animalId: string;
  primaryColor: TOptionType;
};
export type TAnimalPrimaryColorUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalBreedUpdate = {
  animalId: string;
  breed: TOptionType;
};

export type TAnimalBreedUpdateUi = TAnimalCreatableFieldUpdateUi;
export type TAnimalSecondaryColor1Update = {
  animalId: string;
  colorId: string;
};

export type TApplicationSADocPacketGetUI = TSADocPacketGetUI & {
  fails?: TFailUI[];
};
export type TApplicationSADocPacketGet = TSADocPacketGet & { fails?: TFail };

export type TAnimalApplicationVetRecordsUI = TAnimalVetRecordsListUi & {
  fails: TFailUI[];
};
export type TAnimalApplicationVetRecords = TAnimalVetRecordsList & {
  fails?: TFail;
};

export type TApplicationAnimalUI = Omit<
  TAnimalDetailsUi,
  'vetRecordList' | 'packets'
> & {
  fails: TFailUI[];
  documentsFails?: TFailUI[];
  vetRecordList: TAnimalApplicationVetRecordsUI[];
  packets: TApplicationSADocPacketGetUI[];
};

export type TApplicationAnimal = Omit<
  TAnimalDetails,
  'vetRecords' | 'saDocumentPackets'
> & {
  fails?: TFail;
  vetRecords: TAnimalApplicationVetRecords[] | null;
  saDocumentPackets: TApplicationSADocPacketGet[] | null;
};

export enum EResidencyStateDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  NONE = 'NONE',
  FUTURE = 'FUTURE',
}

export enum EResidencyStateUI {
  APPROVED = 'Moved In',
  PENDING = 'Pending',
  NONE = 'None',
  FUTURE = 'Future Animal',
}
export enum EAnimalStatusDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  MOVED_OUT = 'MOVED_OUT',
  ARCHIVED = 'ARCHIVED',
  RETRACTED = 'RETRACTED',
  MOVED_IN = 'MOVED_IN',
  CONDITIONALLY_MOVED_IN = 'CONDITIONALLY_MOVED_IN',
  CONDITIONALLY_APPROVED = 'CONDITIONALLY_APPROVED',
}

export enum EAnimalStatusUI {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  NOT_APPROVED = 'Not approved',
  MOVED_OUT = 'Moved out',
  ARCHIVED = 'Archived',
  RETRACTED = 'Retracted',
  MOVED_IN = 'Moved in',
  CONDITIONALLY_MOVED_IN = 'Conditionally Moved In',
  CONDITIONALLY_APPROVED = 'Conditionally Approved',
}

export type TAnimalDB = {
  animalId: string;
  animalName: string;
  animalSpecie: string;
  animalProfilePicture: string;
  animalType: EAnimalTypes;
  tenantName: string;
  tenantId: string;
  propertyManagementId: string | null;
  propertyManagementName: string | null;
  propertyManagementLogo: string | null;
  propertyId: string;
  propertyName: string;
  animalResidencyState: EResidencyStateDB;
  animalStatus: EVerificationStatus;
  creationTime: string;
  lastFollowedUpTime: string;
  read: boolean;
};

export type TAnimalInfoUI = {
  animalId: string;
  animalName: string;
  animalSpecie: string;
  animalProfilePicture: string;
  animalType: EAnimalTypesUI;
  animalResidencyState: EResidencyStateUI;
  animalStatus: EVerificationStatusUi;
  firstLetters: string;
};

export type TAnimalInfoDB = {
  animalId: string;
  animalName: string;
  animalSpecie: string;
  animalProfilePicture: string;
  animalType: EAnimalTypes;
  animalResidencyState: EResidencyStateDB;
  animalStatus: EVerificationStatus;
};
export type TTenantInfoDB = {
  tenantName: string;
  tenantId: string;
};
export type TTenantInfoUI = {
  tenantName: string;
  tenantId: string;
};

export type TAnimalUI = {
  animalInfo: TAnimalInfoUI;
  tenantInfo: TTenantInfoUI;
  propertyManagement: TPropertyManagementInfoUI;
  propertyInfo: TPropertyInfoUI;
  creationTime: string;
  lastFollowedUpTime: string;
  read: boolean;
};

export enum ESortFields {
  creationTime = 'CREATION_TIME',
  animalInfo = 'NAME',
  lastFollowedUpTime = 'LAST_FOLLOWED_UP_TIME',
}

export enum ENotBeingChargedReasonDB {
  PET_FEE = 'PET_FEE',
  PET_RENT = 'PET_RENT',
}
export enum ENotBeingChargedReasonUI {
  PET_FEE = 'Pet Fee',
  PET_RENT = 'Pet Rent',
}

export type TChargingStatusUpdateUI = {
  tenantResidencyId: string;
  animalResidencyId: string;
  chargingStatus: EAnimalPetChargeStatusUI;
  reason?: string;
  shouldTagAnimalAsPNC?: boolean;
  notBeingChargedReason?: ENotBeingChargedReasonUI;
};

export type TChargingStatusUpdateDB = {
  tenantResidencyId: string;
  animalResidencyId: string;
  chargingStatus: EAnimalPetChargeStatusDB;
  reason?: string;
  shouldTagAnimalAsPNC?: boolean;
  notBeingChargedReason?: ENotBeingChargedReasonDB;
};

export type TMarkAsPNCDB = {
  tenantResidencyId: string;
  animalResidencyId: string;
};
export type TMarkAsPNCUI = {
  tenantResidencyId: string;
  animalResidencyId: string;
};

export type TRemoveApplicantDiscoveredUI = {
  tenantResidencyId: string;
  applicationId: string;
};
export type TRemoveApplicantDiscoveredDB = {
  tenantResidencyId: string;
  applicationId: string;
};

export type TMarkAsDiscoverdApplicantUI = {
  tenantResidencyId: string;
  applicationId: string;
  discoveredTagReason?: string;
};

export type TMarkAsDiscoverdApplicantDB = {
  tenantResidencyId: string;
  applicationId: string;
  discoveredTagReason?: string;
};
export type TMarkAsDiscoverdUI = {
  tenantResidencyId: string;
  animalResidencyId: string;
  discoveredTagReason?: string;
};

export type TMarkAsDiscoverdDB = {
  tenantResidencyId: string;
  animalResidencyId: string;
  discoveredTagReason?: string;
};
